<script setup lang="ts">
  import { Text } from '@bt/design-system'
  import type { Report, StructuredFinancialReport } from '@/types/Storyblok/General/StructuredFinancialReport'
  import { parseAssetPath } from '~/utils/parseAssetPath'

  const props = defineProps<{ blok: StructuredFinancialReport[] }>()

  const { t } = useI18n()
  const config = useRuntimeConfig()

  const reportItems = computed(() => {
    return props.blok
      .filter((item) => getFileUrl(item.report)?.includes(config.public.btAssetUrl))
      .map((item) => ({
        ...item,
        name: getName(item.report),
        href: getFileUrl(item.report),
        fileType: item.fileType.length ? item.fileType : `${getFileType(item.report)} ${t('file')}`
      }))
  })
  const getFileUrl = (report: Report) => {
    const file = report.content?.button?.[0]?.file

    if (!file) {
      return ''
    }

    return parseAssetPath(file.cached_url ?? file.url)
  }
  const getFileType = (report: Report) => {
    const fileType = report.content?.button?.[0]?.file?.cached_url || ''

    return fileType.split('.').pop()?.toLocaleUpperCase()
  }
  const getName = (report: Report) => {
    return report.content?.button?.[0]?.name || ''
  }
</script>

<template>
  <div class="structured-financial-reports">
    <div
      v-for="item in reportItems"
      :key="item._uid"
      class="structured-financial-report"
    >
      <img
        class="structured-financial-report__icon rounded-lg"
        :src="item.icon.filename"
        :alt="item.icon.alt"
      />
      <div class="structured-financial-report__content">
        <Text
          type="body1"
          :text="item.name"
          tag="a"
          :href="item.href"
          color="text-bt-ui-dark-blue"
          class="structured-financial-report__title"
          target="_blank"
          rel="noopener noreferrer"
        />
        <Text
          type="body2"
          :text="item.fileType"
          tag="p"
          color="text-bt-ui-secondary-text"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use '@/assets/styles/variables.scss' as *;
  @use '@/assets/styles/breakpoints.scss' as *;

  .structured-financial-reports {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .structured-financial-report {
    display: flex;
    gap: 16px;

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__icon {
      background-color: $white;
      height: fit-content;
    }

    &__title {
      text-decoration: underline;
    }

    @include desktop-l-up {
      gap: 16px;
    }
  }
</style>
